<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading"></loading>
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{$t('message.register_now_text')}}</h1>
            <p>{{$t('message.login_information')}}</p>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <form @submit.prevent="submit">
                <b-row>
                  <b-col sm="12">
                    <validation-field
                      :label="$t('message.email_unregistered')"
                      :v="$v.form.email">
                      <input class="form-control" id="email"
                             maxlength="50"
                             type="text"
                             v-model="form.email"
                             v-on:blur="$v.form.email.$touch()"
                      />
                    </validation-field>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <validation-field :label="$t('message.password')"
                                      :v="$v.form.pw"
                    >
                      <input class="form-control" id="pw"
                             maxlength="50"
                             type="password"
                             v-model="form.pw"
                             v-on:keydown="$v.form.pw.$touch()"/>
                    </validation-field>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <validation-field :label="$t('message.label_confirm_password')"
                                      :v="$v.form.pw_confirm">
                      <input class="form-control"
                             maxlength="50"
                             type="password"
                             v-model="form.pw_confirm"
                             v-on:blur="$v.form.pw_confirm.$touch()"/>
                    </validation-field>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <validation-field
                      :label="$t('message.language')"
                      :v="$v.form.Language_Code"
                    >
                      <select class="form-control" v-model="form.Language_Code"
                              v-on:blur="$v.form.Language_Code.$touch()">
                        <option value="ITA">{{$t('message.italian')}}</option>
                        <option value="FRA">{{$t('message.french')}}</option>
                        <option value="ESP">{{$t('message.spanish')}}</option>
                        <option value="DEU">{{$t('message.german')}}</option>
                        <option value="ENU">{{$t('message.english')}}</option>
                        <!--
                        <option value="RUS">{{$t('message.russian')}}</option>
                        <option value="POR">{{$t('message.portuguese')}}</option>
                        -->
                      </select>
                    </validation-field>
                  </b-col>
                </b-row>
                <b-row :class="{invalid: $v.form.privacy.$invalid}">
                  <b-col sm="12">
                    <validation-field
                                      :v="$v.form.privacy" :is-checkbox="true">
                      <input class="form-check-input" type="checkbox"
                             v-model="form.privacy"
                             v-on:blur="$v.form.privacy.$touch()"/>
                      <label class="form-check-label">
                        <router-link :to="{name:'tos'}" target="_blank">{{$t('message.label_tos')}}</router-link>
                      </label>
                    </validation-field>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">

                    <validation-field :v="$v.form.adm" :is-checkbox="true">
                      <input class="form-check-input" type="checkbox" v-model="form.adm"
                             v-on:blur="$v.form.adm.$touch()">
                      <label class="form-check-label">
                        <router-link :to="{name:'gdpr'}" target="_blank">{{$t('message.label_gdpr')}}</router-link>
                      </label>
                    </validation-field>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <validation-field
                                      :v="$v.form.mktg" :is-checkbox="true">
                      <input class="form-check-input" type="checkbox" v-model="form.mktg">
                      <label class="form-check-label">{{$t('message.label_newsletter')}}</label>
                    </validation-field>
                  </b-col>
                </b-row>
                <b-row style="margin-top: 25px">
                  <b-col md="4" sm="12">
                    <b-input-group :class="{ 'error': $v.form.recaptchaVerified.$error }" class="mb-3">
                      <vue-recaptcha
                        v-if="sitekey"
                        :loadRecaptchaScript="true"
                        :sitekey="sitekey"
                        :language="this.$route.params.lang"
                        @expired="onExpired"
                        @verify="onVerify"
                        ref="recaptcha"
                      >
                      </vue-recaptcha>

                      <validation-field
                        v-else
                        :label="$t('message.recapthca_in_dev')"
                        :v="$v.form.recaptchaVerified">
                        <input class="form-control" type="checkbox" v-model="form.recaptchaVerified">
                      </validation-field>

                      <span class="error" v-if="$v.form.recaptchaVerified.$error">Obbligatorio</span>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr class="mb-4">
                <b-row>
                  <b-col md="6" sm="12">
                    <b-button :class="{invalid:$v.form.$invalid}" block class="bg-theme-secondary mb-2"
                              type="submit">
                      {{$t('message.button_send_text')}}
                    </b-button>
                  </b-col>
                  <b-col md="6" sm="12">
                    <b-button block type="button" v-on:click="reset()"
                              variant="secondary">
                      {{$t('message.button_reset_text')}}
                    </b-button>
                  </b-col>
                </b-row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import ValidationField from "../components/ui/ValidationField";
  import ApiResolver from '../components/common/ApiResolver';
  import Api from '../components/common/Api';
  import Auth from "../components/auth/Auth";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {email, helpers, required, sameAs, minLength, minValue} from 'vuelidate/lib/validators'
  import VueRecaptcha from 'vue-recaptcha';

  import store from '@/store';

  const atLeastOneUppercaseLetter = helpers.regex('alpha', /[A-Z]/);
  const atLeastOneLowercaseLetter = helpers.regex('alpha', /[a-z]/);
  const atLeastOneNumber = helpers.regex('alpha', /[0-9]/);
  const atLeastOneSymbol = helpers.regex('alpha', /(?=.*?[#?!@$%&*_~])/);
  const notSuchSymbols = helpers.regex('alpha', /^[a-zA-Z0-9#?!@$%&*_~]*$/);

  export default {
    name: 'pre-register',
    components: {
      ValidationField,
      Loading,
      VueRecaptcha
    },
    data() {
      return {
        isLoading: false,
        sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
        form: {
          email: '',
          pw: '',
          pw_confirm: '',
          privacy: false,
          adm: false,
          mktg: false,
          Language_Code: null,
          recaptchaVerified: false
        }
      };
    },
    computed: {},
    methods: {
      onVerify: function (response) {
        this.form.recaptchaVerified = true
      },
      onExpired: function () {
        //console.log('Expired')
      },
      resetRecaptcha() {
        this.$refs.recaptcha.reset() // Direct call reset method
      },
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return
        this.isLoading = true;
        Api
          .post(ApiResolver.get('PRE_REGISTER'), JSON.stringify(this.form))
          .then(response => {
            this.isLoading = false;
            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.check_mail_for_token')
            });
            // se vogliamo autenticare automaticamente senza fare il login, scommentare:
            /*
            this.$store.dispatch('user/setToken', {
              token: response.data.token
            });
            */
            store.dispatch('user/logout');
            this.$router.push({name: 'login'});
          })
          .catch(reason => {
            this.isLoading = false;

            this.$store.dispatch('alerts/setAlertValidation', reason);

          });
      },
      reset() {
        this.form = {
          email: null,
          pw: null,
          pw_confirm: null,
          privacy: null,
          adm: null,
          mktg: null,
          Language_Code: null
        };
        this.$v.form.reset();
      }
    },
    validations: {
      form: {
        email: {
          required: required,
          email: email
        },
        pw: {
          required: required,
          atLeastOneUppercaseLetter,
          atLeastOneLowercaseLetter,
          atLeastOneNumber,
          min: minLength(6),
          atLeastOneSymbol,
          notSuchSymbols
        },
        pw_confirm: {
          required: required,
          sameAs: sameAs('pw')
        },
        privacy: {
          required,
          sameAs: sameAs(() => true)
        },
        adm: {
          required,
          sameAs: sameAs(() => true)
        },
        mktg: {
          required: false
        },
        Language_Code: {
          required: required
        },
        recaptchaVerified: {
          sameAs: sameAs(() => true)
        }
      }

    },
    beforeCreate() {

    },
    mounted() {

      Auth.getCurrentUser().then(response => {

        this.currentUser = response;
        //console.log(this.currentUser);
        /*
        if(this.currentUser.anonymous === 1) {

            window.location.href = '/products';
        }
        */
      });


    },
  };
</script>

<style scoped>
  .hasError label {
    color: red;
  }
  .error {
    color: red
  }
</style>


